$txtFamily: "Noto Sans TC";

.ant-modal-close {
  display: none;
}

.ant-modal-content {
  border-radius: 20px;
}

.ant-modal-body {
  padding-left: 30px;
  padding-right: 30px;
}

.ant-notification {
  border-radius: 20px !important;
}

// Donutchart
.rd-donut-chart .donutchart {
  width: 100% !important;
  position: relative;
  .donutchart-legend {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  text {
    font-size: 12px !important;
  }
}

// Side Menu Animation
.fade-in {
  opacity: 1;
  animation: fade-in 0.15s ease-in;
  transition: 0.2s;
}
.fade-out {
  opacity: 1;
  animation: fade-out 0.15s ease-out;
  transition: 0.2s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// Header
.language-select-dropdown-header {
  padding-left: 10px !important;
  height: 30px !important;
  border-radius: 5px !important;
  .ant-select-selector:focus,
  .ant-select-selector:focus-visible {
    --tw-ring-color: transparent !important;
    border: none !important;
  }
  .ant-select-selector {
    height: 30px !important;
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    align-items: center !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0 !important;
  }
  .ant-select-selection-item {
    padding-left: 15px !important;
    font-size: 12px !important;
  }

  .ant-select-arrow {
    right: 12px !important;
    font-size: 10px !important;
    color: #000 !important;
  }
}

// Login
.language-select-dropdown-login {
  border: solid 1px #cccccc !important;
  height: 30px !important;
  border-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
  .ant-select-item-option-content {
    background-color: transparent !important;
  }
  .ant-select-selector {
    color: #fff !important;
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    align-items: center !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0 !important;
  }

  .ant-select-selection-item {
    padding-left: 15px !important;
    font-size: 12px !important;
  }
  .ant-select-arrow {
    right: 12px !important;
    font-size: 10px !important;
    color: #fff !important;
  }
}

.transaction-select-dropdown {
  .ant-select {
    font-size: 12px !important;
    box-shadow: none !important;
  }

  .ant-select-selector {
    border: none !important;
  }

  .ant-select-arrow {
    color: #000 !important;
  }

  .ant-select-dropdown
    .ant-select-item
    .ant-select-item-option
    .ant-select-item-option-active {
    font-size: 12px !important;
  }
}
.ant-select-item-option-content {
  font-size: 12px !important;
}

.transfer-out-select-dropdown,
.onshore-withdraw-select-dropdown {
  .ant-select-selector {
    height: 40px !important;
    align-items: center;
    display: flex;
  }
  .ant-select-selection-item {
    font-size: 12px !important;
  }

  .ant-select {
    border-radius: 5px !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px !important;
    color: #000 !important;
  }

  .ant-select-arrow {
    top: 20px !important;
  }
}

.network-select-dropdown {
  .ant-select-selector {
    display: flex !important;
    align-items: center !important;
    border-radius: 5px !important;
    height: 40px !important;
  }
  .ant-select {
    font-size: 12px !important;
  }

  .ant-select-selection-item {
    font-size: 13px !important;
  }
}

.register-account-select-dropdown {
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selector:focus-visible {
    outline: #4d91fe solid 1px !important;
  }

  .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
    border-color: #e5e7eb !important;
  }
}
// Replace Default Picker Hover Style
.ant-picker-decade-btn,
.ant-picker-year-btn,
.ant-picker-header-next-btn,
.ant-picker-header-prev-btn,
.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  box-shadow: none !important;
}

.convert-select-dropdown {
  .ant-select-selector {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    border: none !important;
    border-color: transparent !important;
    background-color: transparent !important;
    height: 40px !important;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }

  .ant-select {
    font-size: 11px !important;
  }

  .ant-select-selection-item {
    font-size: 12px !important;
  }
}

// Input (Disable Arrow for number type)
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Master Card Upload Image Button
.master-card-upload {
  button:hover {
    transition: none !important;
    box-shadow: none !important;
  }
}

.master-card-upload:hover {
  transition: 0.7s;
  transition-duration: 0.7s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: all;
  box-shadow: 0px 0px 5px 1px #e3b354;
}

// Master Card Upload Modal Footer Button
.ant-modal-header {
  border-radius: 20px !important;
}

.ant-modal-footer .ant-btn-primary {
  border-color: #e3b354 !important;
  background-color: #e3b354 !important;
  border-radius: 5px;
  margin-right: 12px;
  letter-spacing: 0 !important;
}

.ant-modal-footer .ant-btn-default {
  background-color: transparent !important;
  color: #e3b354;
  border-color: #e3b354;
  letter-spacing: 0 !important;
}

.master-card-ranger-picker {
  .ant-picker-input > input {
    font-size: 12px !important;
  }
}

.ant-notification-notice-description,
.ant-notification-notice-message {
  font-family: $txtFamily;
}
