$logoFamily: "Noto Sans TC";
$titleFamily: "Noto Sans TC";
$subTitleFamily: "Noto Sans TC";
$txtFamily: "Noto Sans TC";

$txtBlack: #000;
$content: #666;
$containerBorder: #e3e3e3;
$disabledBg: #f0f2f5;

// Size
$tiny: 10px;
$small: 11px;
$medium: 13px;
$large: 14px;
$xlarge: 16px;
$xxlarge: 18px;
$xxxlarge: 60px;

h1,
h2,
h3,
h4,
h5 {
  color: $txt;
  font-weight: 500;
  margin-bottom: 0px;
}

#inner-container {
  h1 {
    color: black;
    font-size: $xxlarge;
  }
}

h1 {
  font-family: $titleFamily;
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
}

h2 {
  font-family: $titleFamily;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}

h3 {
  font-family: $titleFamily;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

h4,
h5 {
  font-family: $subTitleFamily;
  font-size: 14px;
}

p,
a,
li,
fix pre,
th,
td {
  font-family: $txtFamily;
  letter-spacing: 0px;
  color: $txt;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
}

p.balance {
  font-size: 10px;
  color: #e3b354;
}

p.content {
  color: #666;
  font-size: 12px;
}

p.note {
  color: #999;
  font-size: 10px;
}

p.label {
  color: #666;
  font-size: 12px;
}

p.title {
  font-size: 13px;
  font-weight: 600;
}

.text-input {
  width: 100%;
  border-width: 1px;
  padding: 5px 10px;
  margin-top: 5px;
  border-radius: 5px;
  height: 40px;
  color: $content;
  font-size: 13px;
}

.disabled-text-input {
  width: 100%;
  border-width: 1px;
  padding: 5px 10px;
  margin-top: 5px;
  border-radius: 5px;
  height: 40px;
  background-color: $disabledBg;
  color: $txtBlack;
  font-size: 13px;
}

.text-input {
  width: 100%;
  border-width: 1px;
  padding: 5px 10px;
  margin-top: 5px;
  border-radius: 5px;
  height: 40px;
  color: $content;
  font-size: 13px;
}

.inactive-button-gradient {
  color: $txtBlack;
  font-size: 12px;
  border: 1px solid transparent;
  background: transparent !important;
  font-weight: 700;
}

.button-gradient {
  width: 100%;
  background: linear-gradient(to right, #f3e1a3, #e3b354);
  border: 1px solid $primary;
  color: $txtBlack;
  font-size: 14px;
  font-weight: 700;
}

.button-secondary {
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  color: $txtBlack;
  font-size: 14px;
  width: 100%;
}

.button-outline {
  border: 1px solid #e3b354;
  padding: 5px 25px;
  color: $txtBlack;
  font-size: 12px;
  font-weight: 700;
}

.container-bg-gradient {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid $containerBorder;
  border-radius: 5px;
  background: linear-gradient(to bottom, #f1f1f1, #efefef4d);
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 5px;
}

// Donut Chat <text>
text {
  font-family: $txtFamily;
  color: $txt;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

button.gradient {
  background-image: linear-gradient(to right, #f3e1a3, #e3b354);
  width: 100%;
  color: black;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding: 10px 0;
  border: none;
  cursor: pointer;
}

button.cancel {
  background-color: #eee;
  width: 100%;
  color: black;
  font-size: 14px;
  font-weight: 700;
}

.form-text {
  color: grey;
}

.rotated {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}

.rotated-invert {
  writing-mode: vertical-rl;
  transform: rotate(0);
}

.txt-gradient {
  background: -webkit-linear-gradient(left, $primary, $secondary);
  background: -o-linear-gradient(right, $primary, $secondary);
  background: -moz-linear-gradient(right, $primary, $secondary);
  background: linear-gradient(to right, $primary, $secondary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.txt-dark-gradient {
  background: -webkit-linear-gradient(left, $primary-dark, $secondary);
  background: -o-linear-gradient(right, $primary-dark, $secondary);
  background: -moz-linear-gradient(right, $primary-dark, $secondary);
  background: linear-gradient(to right, $primary-dark, $secondary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 768px) {
  .rotated {
    writing-mode: horizontal-tb;
    transform: rotate(0);
  }
}
